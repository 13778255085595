<template>
  <div>
    <header-slot :clients-search="true">
      <template #actions>
        <div class="d-flex justify-content-end">
          <court-info-action />

          <!-- Share -->
          <b-button
            v-if="currentUser.role_id == 1 || currentUser.role_id == 2"
            :disabled="isResponsibleAccount"
            class="custom-button"
            @click="openModalShare"
          >
            <feather-icon icon="Share2Icon" />
            Share
          </b-button>
          <!-- Other Programs -->
          <b-button
            :disabled="client != null ? client.count_program == 0 : ''"
            class="ml-1 custom-button"
            @click="openModalProgramList"
          >
            <feather-icon icon="MonitorIcon" />
            Others Programs
          </b-button>
          <!-- Send CR -->
          <b-dropdown
            class="ml-1 custom-button"
            right
            text="Send CR"
            variant="transparent"
            :disabled="isResponsibleAccount"
            toggle-class="d-flex align-items-center dropdown-user-link"
          >
            <template #button-content>
              <feather-icon icon="FileTextIcon" class="text-white" />
              <p class="text-white m-0" style="padding-left: 2px">Request CR</p>
              <b-badge
                v-if="countNcrForUpdate > 0"
                v-b-tooltip.hover
                title="The specialist requests a new NCR because it has already expired"
                variant="info"
                class="custom-badge-number"
                >{{ countNcrForUpdate }}</b-badge
              >
              <b-badge
                v-if="cPendingNcrByClient > 0"
                v-b-tooltip.hover
                title="The client has pending requests"
                variant="warning"
                class="custom-badge-number"
                >{{ cPendingNcrByClient }}</b-badge
              >
            </template>
            <b-dropdown-item @click="openModalCreateNcr('AUTOMATIC')">
              Automatic
            </b-dropdown-item>
            <b-dropdown-item @click="openModalCreateNcr('MANUAL')">
              Manual
            </b-dropdown-item>
            <b-dropdown-item @click="showCreditReport = true">
              History
              <b-badge v-if="cPendingNcrByClient > 0" variant="warning">{{
                cPendingNcrByClient
              }}</b-badge>
            </b-dropdown-item>
          </b-dropdown>
          <!-- Send -->
          <b-dropdown
            class="ml-1 custom-button"
            right
            :disabled="isResponsibleAccount"
            variant="transparent"
            toggle-class="d-flex align-items-center dropdown-user-link"
          >
            <template #button-content>
              <feather-icon icon="SendIcon" class="text-white" />
              <p class="text-white m-0" style="padding-left: 2px">Send</p>
            </template>
            <b-dropdown-item>
              <div class="d-flex justify-content-between align-items-center">
                <span @click="openModalSendSms">Send SMS</span>
                <feather-icon
                  icon="MoreVerticalIcon"
                  @click="openModalHistorySms"
                />
              </div>
            </b-dropdown-item>
            <b-dropdown-item style="cursor: none">
              <div class="d-flex justify-content-between align-items-center">
                <span style="width: 6px" @click="openModalSendEmail"
                  >Send Email</span
                >
                <feather-icon
                  icon="MoreVerticalIcon"
                  @click="openModalHistoryEmail"
                />
              </div>
            </b-dropdown-item>
            <b-dropdown-item>
              <div class="d-flex justify-content-between align-items-center">
                <span style="width: 6px" @click="openModalLetter"
                  >Send Letter</span
                >
                <feather-icon
                  icon="MoreVerticalIcon"
                  @click="openModalHistoryLetter"
                />
              </div>
            </b-dropdown-item>
            <b-dropdown-item
              :disabled="client.status_call_round != 1 || validateStatusCall"
              @click="openModalCallRound(client.status_call_round)"
            >
              Call Round
            </b-dropdown-item>

            <b-dropdown-item style="cursor: none">
              <div class="d-flex justify-content-between align-items-center">
                <span style="width: 6px" @click="openModalSendStatement"
                  >STATEMENT</span
                >
                <feather-icon
                  icon="MoreVerticalIcon"
                  @click="openModalHistoryStatement"
                />
              </div>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
    </header-slot>

    <b-card no-body class="m-0">
      <div class="my-2 mx-3">
        <div class="d-flex justify-content-between">
          <!-- client info -->
          <div>
            <b-skeleton-wrapper :loading="!validateAccount">
              <template #loading>
                <b-card no-body>
                  <b-skeleton width="85%" style="min-width: 124px" />
                  <b-skeleton width="55%" style="min-width: 95px" />
                  <b-skeleton width="70%" style="min-width: 110px" />
                </b-card>
              </template>
            </b-skeleton-wrapper>
            <template v-if="validateAccount">
              <p>START DATE: {{ client.start_date | myGlobal }}</p>
              <span
                v-if="!isResponsibleAccount"
                style="font-weight: bolder; font-size: 17.5px"
                @click="isResponsibleAccount ? '' : openEditLead()"
                :class="isResponsibleAccount ? '' : 'cursor-pointer'"
                >{{ client.client_name }}</span
              >
              <!-- @click="openEditLead" -->
              <span v-else style="font-weight: bolder; font-size: 17.5px">{{
                client.client_name
              }}</span>
              <br />
              <span
                v-if="
                  $store.getters[
                    'NotificationStore/G_ACCOUNT_IS_RESPONSIBLE_CEO'
                  ].is_responsible
                "
                class="text-primary"
              >
                <tabler-icon
                  :icon="
                    $store.getters[
                      'NotificationStore/G_ACCOUNT_IS_RESPONSIBLE_CEO'
                    ].is_responsible === currentUser.user_id
                      ? 'StarIcon'
                      : 'LockIcon'
                  "
                  size="15"
                />
                <strong>CEO's client</strong>
              </span>
              <div class="d-flex" v-if="validateAccount">
                <span style="font-size: 14.5px">
                  {{ client.account }} | &nbsp;
                </span>
                <span style="font-size: 14.5px">
                  <StatusClientAccount />
                </span>
              </div>
            </template>
          </div>

          <!-- card efectivity -->
          <div style="width: 30%">
            <div v-if="validateAccount">
              <EfectivityCard v-if="showEfectivity" :client="client" />
            </div>
          </div>
        </div>

        <!-- call and file -->
        <b-row v-if="client.track_id != null">
          <b-col lg="auto">
            <b-input-group prepend="FILE">
              <b-input-group-append>
                <div
                  :style="isDarkSkin ? 'opacity: 0.6' : ''"
                  class="form-group-input2 d-flex align-content-center align-items-center"
                >
                  <feather-icon
                    v-if="client.file_status == null"
                    class="text-secondary cursor-pointer"
                    size="21"
                    icon="CheckCircleIcon"
                    @click="checkstatus(client.track_id, 1, 1)"
                  />
                  <feather-icon
                    v-else-if="client.file_status != null"
                    v-b-tooltip.hover.left="client.file_status"
                    class="cursor-pointer text-success"
                    size="21"
                    icon="CheckCircleIcon"
                    @click="checkstatus(client.track_id, 1, 2)"
                  />
                </div>
              </b-input-group-append> </b-input-group
          ></b-col>
          <b-col lg="auto">
            <b-input-group prepend="CALL">
              <b-input-group-append>
                <div
                  :style="isDarkSkin ? 'opacity: 0.6' : ''"
                  class="form-group-input2 d-flex align-content-center align-items-center"
                >
                  <div class="text-center">
                    <b-badge
                      v-if="client.track_finish == null ? true : false"
                      class="cursor-pointer"
                      variant="danger"
                      @click="
                        checkstatus(client.track_id, 2, 1, client.dates_calls)
                      "
                    >
                      <feather-icon icon="MinusIcon" />
                    </b-badge>
                    <b-badge
                      :id="'call-target-' + client.track_id"
                      variant="primary"
                      style="
                        width: 40px;
                        margin-left: 0.3rem;
                        margin-right: 0.3rem;
                      "
                    >
                      {{ client.call_status }}
                    </b-badge>
                    <b-tooltip
                      v-if="
                        (JSON.parse(client.dates_calls)
                          ? JSON.parse(client.dates_calls).length
                          : 0) > 0
                      "
                      :key="cont"
                      :target="'call-target-' + client.track_id"
                      triggers="hover"
                      placement="left"
                    >
                      <div>
                        <span
                          v-for="(item, i) in JSON.parse(client.dates_calls)"
                          :key="i"
                        >
                          {{ item }}<br />
                        </span>
                      </div>
                    </b-tooltip>
                    <b-badge
                      v-if="client.track_finish == null ? true : false"
                      class="cursor-pointer"
                      variant="success"
                      @click="
                        checkstatus(client.track_id, 2, 2, client.dates_calls)
                      "
                    >
                      <feather-icon icon="PlusIcon" />
                    </b-badge>
                  </div>
                </div>
              </b-input-group-append> </b-input-group
          ></b-col>
          <b-col lg="auto">
            <b-input-group prepend="SMS">
              <b-input-group-append>
                <div
                  :style="isDarkSkin ? 'opacity: 0.6' : ''"
                  class="form-group-input2 d-flex align-content-center align-items-center"
                >
                  <div class="text-center">
                    <b-badge
                      v-if="client.track_finish == null ? true : false"
                      variant="danger"
                      class="cursor-pointer"
                    >
                      <feather-icon
                        icon="MinusIcon"
                        @click="
                          checkstatus(client.track_id, 3, 1, client.dates_sms)
                        "
                      />
                    </b-badge>
                    <b-badge
                      :id="'sms-target-' + client.tracking_id"
                      variant="primary"
                      style="
                        width: 40px;
                        margin-left: 0.3rem;
                        margin-right: 0.3rem;
                      "
                    >
                      {{ client.sms_status }}
                    </b-badge>
                    <b-tooltip
                      v-if="
                        (JSON.parse(client.dates_sms)
                          ? JSON.parse(client.dates_sms).length
                          : 0) > 0
                      "
                      :key="cont"
                      :target="'sms-target-' + client.tracking_id"
                      triggers="hover"
                      placement="left"
                    >
                      <div>
                        <span
                          v-for="(item, i) in JSON.parse(client.dates_sms)"
                          :key="i"
                        >
                          {{ item }}<br />
                        </span>
                      </div>
                    </b-tooltip>
                    <b-badge
                      v-if="client.track_finish == null ? true : false"
                      variant="success"
                      class="cursor-pointer"
                      @click="
                        checkstatus(client.track_id, 3, 2, client.dates_sms)
                      "
                    >
                      <feather-icon icon="PlusIcon" />
                    </b-badge>
                  </div>
                </div>
              </b-input-group-append> </b-input-group
          ></b-col>
          <b-col lg="auto" class="d-flex align-items-center">
            <b-button
              v-if="client.track_finish == null ? true : false"
              :disabled="
                !(
                  client.call_status > 0 &&
                  client.file_status != null &&
                  client.sms_status > 0
                )
              "
              size="sm"
              variant="primary"
              @click="finishTracking(client.track_id)"
            >
              <feather-icon
                class="mr-1"
                icon="CheckCircleIcon"
              />FINISH</b-button
            ></b-col
          >
        </b-row>
      </div>

      <b-nav pills class="ml-3 mb-0 custom-tab-amg">
        <b-nav-item
          v-b-tooltip.hover.bottomright="'Personal Information'"
          :to="
            $route.matched[0].meta.general
              ? { name: 'personal-information-boost-credit-general' }
              : { name: 'personal-information-boost-credit' }
          "
          exact-active-class="active border-radius-tabs"
          :link-classes="['h-full px-3', bgTabsNavs]"
          exact
          >Personal Information
        </b-nav-item>
        <b-nav-item
          v-b-tooltip.hover.bottomright="'Notes'"
          :to="
            $route.matched[0].meta.general
              ? { name: 'task-notes-boost-credit-general' }
              : { name: 'task-notes-boost-credit' }
          "
          exact
          exact-active-class="active border-radius-tabs"
          :link-classes="['h-full px-3', bgTabsNavs]"
          >Notes</b-nav-item
        >
        <b-nav-item
          v-b-tooltip.hover.bottomright="'Payments'"
          :to="
            $route.matched[0].meta.general
              ? { name: 'pay-boost-credit-general' }
              : { name: 'pay-boost-credit' }
          "
          exact
          exact-active-class="active border-radius-tabs"
          :link-classes="['h-full px-3', bgTabsNavs]"
          >Pay</b-nav-item
        >
        <b-nav-item
          v-b-tooltip.hover.bottomright="'Files'"
          :to="
            $route.matched[0].meta.general
              ? { name: 'client-files-boost-credit-general' }
              : { name: 'client-files-boost-credit' }
          "
          exact
          exact-active-class="active border-radius-tabs"
          :link-classes="['h-full px-3', bgTabsNavs]"
          >Files
          <span
            v-if="countfileclient > 0"
            class="position-absolute small top-0 start-100 translate-middle badge rounded-pill bg-danger"
            style="
              font-size: 90% !important;
              margin-left: 68px;
              margin-top: -2px;
            "
          >
            {{ countfileclient }}</span
          >
        </b-nav-item>
        <b-nav-item
          v-b-tooltip.hover.bottomright="'Access Credentials'"
          :to="
            $route.matched[0].meta.general
              ? { name: 'acr-boost-credit-general' }
              : { name: 'acr-boost-credit' }
          "
          exact
          exact-active-class="active border-radius-tabs"
          :link-classes="['h-full px-3', bgTabsNavs]"
          >ACr</b-nav-item
        >
        <b-nav-item
          v-b-tooltip.hover.bottomright="'Credit'"
          :to="
            $route.matched[0].meta.general
              ? { name: 'dis-boost-credit-general' }
              : { name: 'dis-boost-credit' }
          "
          exact
          :active="isActiveDispute"
          exact-active-class="active border-radius-tabs"
          :link-classes="['h-full px-3', bgTabsNavs]"
          >Credit
          <b-badge v-if="creditCounter > 0" pill variant="danger" class="ml-1">
            {{ creditCounter }}
          </b-badge>
        </b-nav-item>
        <b-nav-item
          v-b-tooltip.hover.bottomright="'Call Log'"
          :to="
            $route.matched[0].meta.general
              ? { name: 'call-log-boost-credit-general' }
              : { name: 'call-log-boost-credit' }
          "
          exact
          exact-active-class="active border-radius-tabs"
          :link-classes="['h-full px-3', bgTabsNavs]"
          :active="isCallLog"
          >Call Log</b-nav-item
        >
        <b-nav-item
          v-if="validationArrRoles"
          v-b-tooltip.hover.bottomright="'Customer Tickets'"
          :to="
            $route.matched[0].meta.general
              ? { name: 'client-boost-credit-tickets-general' }
              : { name: 'client-boost-credit-tickets' }
          "
          exact
          exact-active-class="active border-radius-tabs"
          :link-classes="['h-full px-3', bgTabsNavs]"
          >Customer Tickets
          <span
            v-if="G_COUNT_PENDING_CUSTOMER_TICKETS_DASHBOARD.v_total_open > 0"
            class="ml-1"
          >
            <feather-icon
              icon
              :badge="
                G_COUNT_PENDING_CUSTOMER_TICKETS_DASHBOARD.v_total_open > 99
                  ? '99+'
                  : G_COUNT_PENDING_CUSTOMER_TICKETS_DASHBOARD.v_total_open
              "
              badge-classes="badge-important"
            />
          </span>
        </b-nav-item>
      </b-nav>
    </b-card>
    <hr class="border-primary border-3 px-0 mt-0" />
    <router-view v-if="showViews" />

    <!-- Header Modals -->
    <lead-update
      v-if="isAddUpdateUserSidebarActive"
      :is-add-new-user-sidebar-active.sync="isAddUpdateUserSidebarActive"
      :lead="S_LEAD_EDIT"
      :type-edit="typeEdit"
      @refresh="refresh"
    />
    <modal-share
      v-if="modalShare"
      :modal-share="modalShare"
      @closeModalShare="closeModalShare"
    />
    <modal-program-list
      v-if="modalProgramList"
      :modal-program-list="modalProgramList"
      @closeModalProgramList="closeModalProgramList"
    />

    <modal-create-ncr-request
      v-if="modalCreateNcr"
      :item="ncrParams"
      :ncr-type="selectedNcr"
      @close="closeModalCreateNcr"
      @reload="refresh"
    />
    <!-- Send -->
    <modal-send-sms
      v-if="modalSendSms"
      :modal-send-sms="modalSendSms"
      :name-leads="{ id: client.id, name: client.client_name }"
      :typesms="0"
      @closeModal="closeSendSms"
    />

    <modal-send-email
      v-if="modalSendEmail"
      :modal-send-email="modalSendEmail"
      :name-leads="[{ id: client.id, name: client.client_name }]"
      :typesms="0"
      @closeModal="closeSendSms"
    />

    <send-letter-modal v-if="modalOpenLetter" @close="closeModalLetter" />

    <modal-history
      v-if="modalHistoryOn"
      :modal-history="modalHistoryOn"
      :name-program="client.program_name"
      :name-client="client.client_name"
      @closeModalHistory="closeModalHistory"
    />
    <send-to-call-rounds
      v-if="modalCallRound"
      :modal-call-round="modalCallRound"
      :name-leads="clientsSms"
      :type-call="0"
      @validateStatus="validateStatus"
      @closeModal="closeModalCallRound"
    />
    <modal-history-sms
      v-if="activeModalHistorySms"
      :active="activeModalHistorySms"
      @close="closeModalHistorySms"
    />
    <modal-history-email
      v-if="activeModalHistoryEmail"
      :active="activeModalHistoryEmail"
      @close="closeModalHistoryEmail"
    />
    <letter-modal-tracking
      v-if="activeModalHistoryLetter"
      :active="activeModalHistoryLetter"
      @close="closeModalHistoryLetter"
    />

    <modal-send-statement
      v-if="modalSendStatement"
      :modal-status-report="modalSendStatement"
      @closeModalStatus="closeModalSendStatement"
    />

    <modal-history-statement
      v-if="modalHistoryStatement"
      :active="modalHistoryStatement"
      @close="closeModalHistoryStatement"
    />

    <credit-report-modal
      v-if="showCreditReport"
      :lead="clientNcr"
      @closeModal="showCreditReport = false"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
// Import Components
import LeadUpdate from "@/views/crm/views/Lead/lead-module/save/LeadUpdate.vue";
import ModalHistory from "@/views/commons/components/clients/dashboard/information-client/modals/ModalHistory";
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";
import LetterModalTracking from "@/views/boost-credit/views/clients/dashboard/information-client/modals/LetterModalTracking";
import EfectivityCard from "@/views/commons/components/ncr-efectivity/CardNcrEfectivity.vue";
// Import Modals

import ModalShare from "@/views/commons/components/clients/dashboard/information-client/modals/ModalShare";
import ModalProgramList from "@/views/commons/components/clients/dashboard/information-client/modals/ModalProgramList";
import ModalCreateNcrRequest from "@/views/commons/components/request-ncr/views/modals/ModalCreateNcrRequest.vue";

import ModalSendSms from "@/views/commons/components/clients/modals/ModalSendSms";
import ModalSendEmail from "@/views/commons/components/clients/modals/ModalSendEmail";
import SendToCallRounds from "@/views/boost-credit/views/clients/components/clients/modals/SendToCallRounds";
import moment from "moment";
import trackingListService from "@/views/commons/components/tracking-list/service/tracking-list.service";
import SendLetterModal from "@/views/boost-credit/views/clients/dashboard/information-client/modals/SendLetterModal";
import ModalHistorySms from "@/views/commons/components/clients/dashboard/information-client/modals-client-information/ModalHistorySms.vue";
import ModalHistoryEmail from "@/views/commons/components/clients/dashboard/information-client/modals-client-information/ModalHistoryEmail.vue";

import ModalSendStatement from "@/views/commons/components/clients/dashboard/information-client/modals/ModalStatusReport.vue";
import ModalHistoryStatement from "@/views/commons/components/clients/dashboard/information-client/modals-client-information/ModalHistoryStatement.vue";

import StatusClientAccount from "@/views/administration/views/clients/components/StatusClientAccount.vue";
import CourtInfoAction from "@/views/debt-solution/views/court-info/components/CourtInfoAction.vue";
import CreditReportModal from "@/views/crm/views/Lead/lead-module/dashboard/modal/CreditReportModal.vue";

export default {
  components: {
    SendToCallRounds,
    ModalShare,
    ModalProgramList,
    ModalSendSms,
    ModalSendEmail,
    LeadUpdate,
    ModalHistory,
    SendLetterModal,
    ModalHistorySms,
    ModalHistoryEmail,
    LetterModalTracking,
    "modal-send-statement": ModalSendStatement,
    ModalHistoryStatement,
    ModalCreateNcrRequest,
    StatusClientAccount,
    CourtInfoAction,
    CreditReportModal,
    EfectivityCard,
  },

  data() {
    return {
      clientNcr: {},
      showCreditReport: false,
      showViews: false,
      cont: 0,
      validateStatusCall: false,
      clientsSms: [],
      validateAccount: false,
      validateImg: false,
      typeEdit: "lead",
      baseImg: process.env.VUE_APP_BASE_URL_FRONT,
      modalShare: false,
      modalProgramList: false,
      modalCreateNcr: false,
      modalCreateNcrRealtor: false,
      modalSendSms: false,
      modalSendEmail: false,
      modalOpenLetter: false,
      modalCallRound: false,
      isAddUpdateUserSidebarActive: false,
      editSidebar: "first",
      modalHistoryOn: false,
      statusstate: false,
      activeModalHistorySms: false,
      activeModalHistoryEmail: false,
      activeModalHistoryLetter: false,

      modalSendStatement: false,
      modalHistoryStatement: false,
      selectedNcr: null,
      countNcrForUpdate: null,
      modalListCourtInfo: false,
    };
  },
  computed: {
    isPersonalInformation() {
      return this.$route.fullPath.includes("personal-information");
    },
    isActiveDispute() {
      return this.$route.matched?.[2]?.name == "dis-boost-credit";
    },
    isCallLog() {
      if (this.$route.matched[2]) {
        const { callConversationRoute, callNoConversationRoute } =
          this.$route.matched[2].meta;
        return [callConversationRoute, callNoConversationRoute].includes(
          this.$route.name
        );
      }
      return false;
    },
    isService() {
      return this.$route.matched?.[2]?.name == "services-client-boost-credit";
    },
    validationArrRoles() {
      // if (
      //   this.currentUser.arrRoles.find(
      //     (rol) =>
      //       (rol.role_id === 2 || rol.role_id === 1 || rol.role_id === 6) &&
      //       rol.module_id !== 24 &&
      //       rol.user_id !== 38
      //   )
      // ) {
      //   return true;
      // }
      // return this.$route.matched?.[2]?.name == "dis-boost-credit";

      return this.currentUser.arrRoles.find(
        (rol) =>
          (rol.role_id === 2 || rol.role_id === 1 || rol.role_id === 6) &&
          rol.module_id !== 24 &&
          rol.user_id !== 38
      );
    },
    statusDark() {
      return this.skin == "dark" ? "dark" : "";
    },
    ...mapState({
      S_LEAD_EDIT: (state) => state.CrmLeadStore.S_LEAD_EDIT,
      S_LEAD: (state) => state.CrmLeadStore.S_LEAD,
    }),
    ...mapGetters({
      currentUser: "auth/currentUser",
      client: "DebtSolutionClients/G_CLIENTS",
      countdisputeclient: "DebtSolutionClients/G_COUNT_DISPUTE",
      countfileclient: "DebtSolutionClients/G_COUNT_FILES",
      G_COUNT_PENDING_CUSTOMER_TICKETS_DASHBOARD:
        "NotificationStore/G_COUNT_PENDING_CUSTOMER_TICKETS_DASHBOARD",
      G_IN_COURT: "ParagonClientDashboard/G_IN_COURT",
      G_COUNTER_ALL_SERVICES: "clients-store/G_COUNTER_ALL_SERVICES",
      cPendingNcrByClient: "RequestNcrStore/G_PENDING_BY_CLIENT",
      G_COUNT_ANALYSIS_CR: "AnalysisCrStore/G_COUNT_ANALYSIS_CR",
      G_COUNT_PENDING_WP_CLIENT: "clients-store/G_COUNT_PENDING_WP_CLIENT",
      updates: "ReportStore/G_UPDATES_DISPUTE",
      missingFiles: "ReportStore/G_MISSING_FILES",
    }),
    creditCounter() {
      return (
        // this.G_COUNT_ANALYSIS_CR.toRemove +
        this.cPendingNcrByClient +
        this.G_COUNT_PENDING_WP_CLIENT +
        this.missingFiles +
        this.updates +
        this.G_COUNTER_ALL_SERVICES
      );
    },
    showEfectivity() {
      return this.client.id != undefined;
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    ncrParams() {
      const {
        id,
        account,
        client_name: client,
        program_id,
        lead_id,
      } = this.client;
      const { user_id, role_id } = this.currentUser;
      return {
        client_account_id: id,
        id, // client_account_id
        account,
        program_id,
        lead_id,
        fullname: client, // full name
        user_id,
        role_id,
        type: "CLIENT",
        used_cards: JSON.stringify([]),
      };
    },
    isResponsibleAccount() {
      if (this.$route.params.idClient) {
        return !(
          this.$store.getters["NotificationStore/G_ACCOUNT_IS_RESPONSIBLE_CEO"]
            .is_responsible === this.currentUser.user_id ||
          this.$store.getters["NotificationStore/G_ACCOUNT_IS_RESPONSIBLE_CEO"]
            .is_responsible === null
        );
      }
      return false;
    },
  },
  async created() {
    this.addPreloader();
    const { data } = await ClientsOptionsServices.getMissingFiles({
      accountId: this.$route.params.idClient,
    });
    await this.$store.dispatch("ReportStore/A_MISSING_FILES", data);
    await this.A_GET_ALL_SERVICES_COUNTER({
      client_account_id: this.$route.params.idClient,
      module_id: this.moduleId,
    });
    await this.A_COUNT_PENDING_WP_CLIENT({
      client_account_id: this.$route.params.idClient,
    });
    await this.getUpdates();
    await this.getCountAccountsToRemove();
    this.$store.dispatch("NotificationStore/A_ACCOUNT_IS_RESPONSIBLE_CEO", {
      account_id: this.$route.params.idClient,
    });
    try {
      await Promise.all([
        this.$store.dispatch("DebtSolutionClients/A_GET_CLIENTS", {
          id: this.$route.params.idClient,
        }),
        this.$store.dispatch("DebtSolutionClients/A_COUNT_FILES", {
          idaccountclient: this.$route.params.idClient,
        }),
        this.$store.dispatch("DebtSolutionClients/A_COUNT_DISPUTE", {
          idaccountclient: this.$route.params.idClient,
        }),
      ]);
      await this.countRequestNcrForUpdate();
      // await this.getCountFiles()
      // await this.getCounterDispute()
      await this.leadInfo();
      this.showViews = true;
      this.removePreloader();
    } catch (error) {
      this.removePreloader();
      console.error(error);
    }
    this.validateImg = true;
    this.validateAccount = true;
    if (this.$route.matched[0].meta.general) {
      this.$store.commit("appConfig/UPDATE_NAV_MENU_HIDDEN", true);
      this.$store.commit("appConfig/UPDATE_NAVBAR_CONFIG", { type: "sticky" });
    }

    await this.getLead(this.client.lead_id);
    // set client NCR
    this.clientNcr = {
      client_account_id: this.client.id,
      account: this.client.account,
      client_id: this.client.client_id,
      lead_id: this.client.lead_id,
      ...this.S_LEAD,
    };
  },
  async mounted() {
    await this.A_COUNT_PENDING_CUSTOMER_TICKETS_DASHBOARD({
      user_id: this.currentUser.user_id,
      client_account_id: this.$route.params.idClient,
    });

    // count pending ncrs of the client
    await this.A_GET_COUNTERS_BY_CLIENT(this.$route.params.idClient);
  },
  destroyed() {
    this.$store.commit("appConfig/UPDATE_NAVBAR_CONFIG", {
      type: "sticky",
    });
    this.$store.commit("appConfig/UPDATE_NAV_MENU_HIDDEN", false);
    this.$store.commit("appConfig/UPDATE_LAYOUT_TYPE", "vertical");
  },
  methods: {
    async getLead(lead_id) {
      try {
        await this.A_GET_LEAD({ id: lead_id });
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    async finishTracking(id) {
      try {
        const response = await this.showConfirmSwal();
        if (response.isConfirmed) {
          const data = await trackingListService.finishTrackingList({
            id,
          });
          if (data.status === 200) {
            this.cont++;
            this.client.track_finish = data.data[0].track_finish;

            this.showSuccessSwal();
          }
        }
      } catch (e) {
        this.showErrorSwal(e);
      }
    },
    async checkstatus(id, type, status, datos) {
      try {
        if (this.client.track_finish == null) {
          const date = moment().format("MM/DD/YYYY hh:mm A");
          const array = datos == null ? [] : JSON.parse(datos);
          if (status === 2) {
            array.push(date);
          } else {
            array.pop();
          }

          const data = await trackingListService.updateStatusTracking({
            id,
            type,
            status,
            newval: array,
          });
          if (data.status === 200) {
            this.client.file_status = data.data[0].file_status;
            this.client.call_status = data.data[0].call_status;
            this.client.dates_calls = data.data[0].dates_calls;
            this.client.sms_status = data.data[0].sms_status;
            this.client.dates_sms = data.data[0].dates_sms;
          }
        }
      } catch (e) {}
    },
    validateStatus() {
      this.validateStatusCall = true;
    },
    async refresh() {
      const data = await this.$store.dispatch(
        "DebtSolutionClients/A_GET_CLIENTS",
        {
          id: this.$route.params.idClient,
        }
      );
    },
    openCloseModalHistory() {
      this.modalHistoryOn = true;
    },
    closeModalHistory() {
      this.modalHistoryOn = false;
    },
    closeModalHistorySms() {
      this.activeModalHistorySms = false;
    },
    closeModalHistoryEmail() {
      this.activeModalHistoryEmail = false;
    },
    closeModalHistoryLetter() {
      this.activeModalHistoryLetter = false;
    },
    async openModalHistorySms() {
      await this.getHistorySms({ id: this.$route.params.idClient, type: 0 });
      this.activeModalHistorySms = true;
    },
    async openModalHistoryEmail() {
      await this.getHistoryEmail(this.$route.params.idClient);
      this.activeModalHistoryEmail = true;
    },
    async openModalHistoryLetter() {
      await this.getHistoryLetter(this.$route.params.idClient);
      this.activeModalHistoryLetter = true;
    },

    ...mapActions({
      A_GET_SELLERS: "CrmGlobalStore/A_GET_SELLERS",
      A_GET_LEAD_EDIT: "CrmLeadStore/A_GET_LEAD_EDIT",
      A_GET_PROGRAMS: "CrmGlobalStore/A_GET_PROGRAMS",
      A_GET_STATE_LEADS: "CrmLeadStore/A_GET_STATE_LEADS",
      A_GET_STATUS_LEADS: "CrmLeadStore/A_GET_STATUS_LEADS",
      A_GET_SOURCE_LEADS: "CrmLeadStore/A_GET_SOURCE_LEADS",
      A_GET_SOURCE_NAMES: "CrmGlobalStore/A_GET_SOURCE_NAMES",
      A_GET_STATES: "CrmGlobalStore/A_GET_STATES",
      A_GET_EEUU_STATES: "CrmGlobalStore/A_GET_EEUU_STATES",
      A_GET_COUNTRIES: "CrmGlobalStore/A_GET_COUNTRIES",
      A_GET_OWNERS: "CrmGlobalStore/A_GET_OWNERS",
      getHistorySms: "ParagonClientDashboard/getHistorySms",
      getHistoryEmail: "ParagonClientDashboard/getHistoryEmail",
      getHistoryLetter: "ParagonClientDashboard/getHistoryLetter",
      A_COUNT_PENDING_CUSTOMER_TICKETS_DASHBOARD:
        "NotificationStore/A_COUNT_PENDING_CUSTOMER_TICKETS_DASHBOARD",
      A_GET_ALL_SERVICES_COUNTER: "clients-store/A_GET_ALL_SERVICES_COUNTER",
      A_GET_COUNTERS_BY_CLIENT: "RequestNcrStore/A_GET_COUNTERS_BY_CLIENT",
      A_GET_LEAD: "CrmLeadStore/A_GET_LEAD",
      A_COUNT_PENDING_WP_CLIENT: "clients-store/A_COUNT_PENDING_WP_CLIENT",
    }),
    async getCountAccountsToRemove() {
      const params = {
        ncrRequestId: 0,
        idClient: this.$route.params.idClient,
        typeView: "REMOVE",
        leadId: 0,
        toViewRates: false,
      };
      await this.$store.dispatch("AnalysisCrStore/A_COUNT_ANALYSIS_CR", params);
    },
    async getUpdates() {
      const { data } = await ClientsOptionsServices.getCountUpdates({
        accountId: this.$route.params.idClient,
      });
      this.$store.dispatch("ReportStore/A_UPDATES_DISPUTE", data);
    },
    async getPrograms() {
      try {
        await this.A_GET_PROGRAMS();
      } catch (error) {
        console.log("Something went wrong getPrograms", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getStateLeads() {
      try {
        await this.A_GET_STATE_LEADS();
      } catch (error) {
        console.log("Something went wrong getStateLeads:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getStatusLeads() {
      try {
        await this.A_GET_STATUS_LEADS();
      } catch (error) {
        console.log("Something went wrong getStatusLeads:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getSourceLeads() {
      try {
        await this.A_GET_SOURCE_LEADS();
      } catch (error) {
        console.log("Something went wrong getSourceLeads:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getSourceNames() {
      try {
        await this.A_GET_SOURCE_NAMES();
      } catch (error) {
        console.log("Something went wrong getSourceNames:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getStates() {
      try {
        await this.A_GET_STATES({ type: 1 });
      } catch (error) {
        console.log("Something went wrong getStates:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getEeuuStates() {
      try {
        await this.A_GET_EEUU_STATES();
      } catch (error) {
        console.log("Something went wrong getEeuuStates:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getCountries() {
      try {
        await this.A_GET_COUNTRIES();
      } catch (error) {
        console.log("Something went wrong getCountries:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },

    async getCounterDispute() {
      try {
        const params = {
          idaccountclient: this.$route.params.idClient,
        };
        const response = await ClientsOptionsServices.counterDispute(params);
        this.countdisputeclient = response.data.length;
      } catch (error) {
        console.log(error);
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong with payment days!"
        );
      }
    },
    async getCountFiles() {
      try {
        const params = {
          idaccountclient: this.$route.params.idClient,
        };
        const response = await ClientsOptionsServices.getFileCountClient(
          params
        );
        this.countfileclient = response.data[0].countfile;
      } catch (error) {
        console.log(error);
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong with payment days!"
        );
      }
    },

    async getOwners() {
      try {
        const roles = [2, 4].includes(this.moduleId) ? "[1,2,5]" : "[1,2,3,5]";
        await this.A_GET_OWNERS({
          modul: this.moduleId,
          body: { roles, type: "1" },
        });
      } catch (error) {
        console.log("Something went wrong getOwners:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    formatPrice(value) {
      const val = (value / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    async openEditLead() {
      this.addPreloader();
      await this.getLeadEdit();
      if (this.S_LEAD_EDIT) {
        this.isAddUpdateUserSidebarActive = true;
      }
      this.removePreloader();
    },
    async leadInfo() {
      // await all promises
      await Promise.all([
        this.getLeadEdit(),
        this.getPrograms(),
        this.getStateLeads(),
        this.getStatusLeads(),
        this.getSourceLeads(),
        this.getSourceNames(),
        this.getStates(),
        this.getEeuuStates(),
        this.getCountries(),
        this.getOwners(),
        this.getSellers(),
      ]);
    },
    async getSellers() {
      try {
        await this.A_GET_SELLERS({
          modul: this.moduleId,
          body: { roles: "[]", type: "1" },
        });
      } catch (error) {
        console.log("Something went wrong getSellers:", error);
      }
    },
    async getLeadEdit() {
      try {
        await this.A_GET_LEAD_EDIT({ id: this.client.lead_id });
      } catch (error) {
        console.log("Something went wrong getLeadEdit", error);
      }
    },
    openModalShare() {
      this.modalShare = true;
    },
    closeModalShare() {
      this.modalShare = false;
    },

    openModalProgramList() {
      this.modalProgramList = true;
    },
    closeModalProgramList() {
      this.modalProgramList = false;
    },

    openModalCreateNcr(type) {
      this.modalCreateNcr = true;
      this.selectedNcr = type;
    },
    closeModalCreateNcr() {
      this.modalCreateNcr = false;
      this.countRequestNcrForUpdate();
    },
    openModalCreateNcrRealtor() {
      this.modalCreateNcrRealtor = true;
    },
    closeModalCreateNcrRealtor() {
      this.modalCreateNcrRealtor = false;
    },
    async openModalCallRound(status) {
      const result = await this.showConfirmSwal("Are you sure?");
      if (result.value) {
        this.clientsSms = [
          {
            id: this.client.id,
            name: this.client.client_name,
            account: this.client.accounts,
            nameAccount: this.client.account,
            nameProgram: this.client.program_name,
          },
        ];
        this.modalCallRound = true;
      }
    },
    closeModalCallRound() {
      this.modalCallRound = false;
    },

    openModalSendSms() {
      this.modalSendSms = true;
    },
    closeSendSms() {
      this.modalSendSms = false;
      this.modalSendEmail = false;
    },
    openModalSendEmail() {
      this.modalSendEmail = true;
    },
    openModalLetter() {
      this.modalOpenLetter = true;
    },
    closeModalLetter() {
      this.modalOpenLetter = false;
    },
    openModalSendStatement() {
      this.modalSendStatement = true;
    },
    closeModalSendStatement() {
      this.modalSendStatement = false;
    },
    openModalHistoryStatement() {
      this.modalHistoryStatement = true;
    },
    closeModalHistoryStatement() {
      this.modalHistoryStatement = false;
    },
    async countRequestNcrForUpdate() {
      const { data } = await ClientsOptionsServices.countRequestNcrForUpdate({
        client_account_id: this.$route.params.idClient,
      });
      this.countNcrForUpdate = data.data;
    },
  },
};
</script>

<style scoped>
* {
  --primary-color: #3f7afa;
}
.content-header .content-header-right button {
  border-color: var(--primary-color) !important;
  background-color: var(--primary-color) !important;
}

.content-header .content-header-right .b-dropdown button {
  border-color: var(--primary-color) !important;
  background-color: var(--primary-color) !important;
}

.av-balance-border-blue {
  border-radius: 7px;
  width: auto;
  padding: 3px;
  border: 1px solid #147ca7;
}

.av-balance-text-blue {
  color: white;
  background-color: #147ca7;
  width: 100px;
  line-height: 13px;
  padding: 7px;
  margin: 0;
  font-size: 15px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 7px;
}

.av-balance-content-blue {
  color: white;
  background-color: #0099c8;
  padding: 7px;
  margin: 0;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}

.av-balance-border-red {
  border-radius: 7px;
  width: auto;
  padding: 3px;
  border: 1px solid #e30613;
}
.form-group-input2 {
  padding: 0.438rem 1rem;
  /* background-color: #fff; */
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  width: 100%;
}

.av-balance-text-red {
  color: white;
  background-color: #e30613;
  width: 100px;
  line-height: 13px;
  padding: 7px;
  margin: 0;
  font-size: 15px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 7px;
}

.av-balance-content-red {
  color: white;
  background-color: #e83b4c;
  padding: 7px;
  margin: 0;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}
.cover-image {
  width: 100px;
  height: 100px;
  background-color: #f8f8f8;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}
</style>
<style scoped>
.send-cr >>> .dropdown-toggle {
  padding: 0.6rem 1.5rem;
  text-align: center;
  display: flex;
  align-items: center;
  gap: 3px;
}
.counter-style {
  width: 21px;
  height: 21px;
  background-color: red;
  padding: 3px;
  border-radius: 50%;
  bottom: 20px;
}
.custom-button {
  background-color: var(--primary-color) !important;
  color: white !important;
  height: 40px !important;
}

.custom-badge-number {
  margin: 0 5px;
}
</style>
